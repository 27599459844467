button, .button {
	border: 1px solid lighten($color-orange, 25%);
	border-bottom: 1px solid $color-orange;
	padding: 0.5em 0.75em;
	text-decoration: none;
	color: $color-orange;
	transition: all 1s ease;
	background: transparent;
	&:hover {
		border-color: $color-orange;
		border-bottom-color: lighten($color-orange, 25%);
		border-radius: 10%;
		color: darken($color-orange, 5%);

	}
}

.button {
    display: inline-block;
}

.button-wrap {
    width: 100%;
    margin-top: 1em;
    text-align: center;
}