.footer-wrap {

	border-top: 1px solid $color-grey-light;
}

.footer {
	max-width: $max-width;
    margin: 0 auto;
    padding: 1em 0;
	text-align: center;
}

.footer-copyright {
    font-size: 0.9em;
}