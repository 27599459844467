.herobar {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	@include mq($max-width) {
		background-attachment: fixed;
	}
}

.herobar-heading {
	margin: 0;
}

.herobar-hp {
	display: block;
	height: 95vh;
	margin-bottom: 2em;
	background: $color-white url('/images/herobar-hp.jpg') no-repeat;
	background-size: contain;
	background-position: center;

	@include mq(wide_tablets) {
		// background-size: 100%;
	}
	@include mq($max-width) {
		background-attachment: fixed;
	}

}

.herobar-contact {
	padding: 8em 0;
	background: #000 url('/images/herobar-contact.jpg') no-repeat;
	background-size: cover;

	.herobar-heading {
		color: $color-white;
	}
}

.herobar-child {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0 auto;
	text-align: center;
}

.hp_herobar-title {
	margin: 0.25em 0 0;
	opacity: 0;
	transform: translateX(-3em);
	color: $color-grey-dark !important;
}

.herobar-logo {
	transition: all 1s ease;
}

.js-opacity {
    transition: all 1s ease;
    opacity: 0;
}

.opacity {
	opacity: 1;
	transform: translateX(0);
	transition: all 1.5s ease;
}