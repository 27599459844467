.ie8_warning-wrap {
    background: $status--danger;
}
.ie8_warning-content {
    // ~70 chars
    max-width: 35em;
    margin: 0 auto;
    padding: 0.8em;
    color: #fff;
    text-align: center;
}
.ie8_warning-content-big {
    font-size: 1.2em;
}
.ie8_warning-link {
    color: #fff;
    &:hover {
        color: #ddd;
    }
}