.content-wrap {
    @include clearfix;
    max-width: $max-width;
    margin: 0 auto;
    padding: 1em;

    &-narrow {
        max-width: 40em;
    }

    &-flex {
        display: flex;
        justify-content: space-between;
    }
}

.content-copy_wrap {
    flex-basis: 55%;
    width: 55%;
    max-width: 55%;
    margin-right: 4%;

    p:first-child {
        margin-top: 0;
    }
}

.content-img_wrap {
    flex-basis: 42%;
    width: 42%;
    max-width: 42%;
    text-align: right;
}

.content-img-center {
    display: block;
    margin: 1em auto;
}
.content-img-left {
    float: left;
    margin: 1em 1em 1em 0;
}
.content-img-right {
    float: right;
    margin: 1em 0 1em 1em;
}

.flex-child-48 {
    flex: 0 0 48%;
    &:first-of-type {
        margin-right: 2em;
    }
}