.header-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    max-width: $max-width;
    margin: 0 auto;
}

.header-nav {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    padding: 0;
    font-weight: 100;
    font-family: 'Futura LT W01 Light';
}

.header-nav_item {
    display: inline-block;
    // margin-right: 3em;
    letter-spacing: 1px;


    &:last-of-type {
        margin-right: 0;
    }

    a {
        text-decoration: none;
        color: $color-grey-dark;

        &:hover {
            color: $color-orange;
        }
    }

    @include mq(wide_tablets) {
        width: 8em;
        text-align: center;
    }

    &-logo {
        @include mq(wide_tablets) {
            margin: 0 2em;
        }
    }
}