.gallery-wrap {
	display: flex;
    flex-flow: row wrap;
	list-style: none;
    margin: 0 auto;
	padding: 0;

	@include mq(wide_tablets) {
        max-width: 1500px;
	}
}

.gallery-item {
    flex: 0 0 33%;
    margin-right: 0.25em;
    &:nth-of-type(3n) {
        margin-right: 0;
    }
}

// .gallery_item-link {
//     display: none;
// }

// Pop up on gallery image click.
.pop_up {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgba($color-white,0.9);
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    // animation-name: animate-opacity;
    // animation-duration: 0.75s;
}

@keyframes animate-opacity {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

.pop_up-content {
    width: 100%;
    // height: 85vh;
    z-index: 101;
}

.img-modal {
    // position: relative;
    // top: 5.5em;
    // left: 0;
    // right: 0;

    height: 100%;
    margin: 0 auto 1em;
    text-align: center;

    @include mq(tablets) {
		display: flex;
	    align-items: center;
	    justify-content: center;
    }
}

.modal_img-wrap img {
    @include mq(tablets) {
        width: auto;
         max-height: 75vh;
    }
}

.display {
    display: block;
}

.gallery-nav-wrap {
	display: flex;
	justify-content: space-between;
	max-width: 80%;
	margin: 0 auto;
}

.gallery_image-info {
	display: flex;
    font-size: 0.8em;
    flex: 0 1 70%;
    margin-left: auto;
}

.gallery_image-title {
    margin-top: 0;
}

.gallery_image-description {
    flex: 0 1 70%;
    justify-content: center;
    margin-left: 1.5em;
}

.gallery_image-sold {
    color: red;
}

.pop_up-next, .pop_up-prev {
    font-size: 1.5rem;
    line-height: 1;
    z-index: 102;
    font-weight: 700;

    a {
        color: #000;
        text-decoration: none;
    }

    @include mq(tablets) {
    	font-size: 3rem;
    }
}

.pop_up-text {
    padding-bottom: 0.25em;
    line-height: 3;
    @include mq(tablets) {
    	font-size: 2rem;
    }

}